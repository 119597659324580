import React from 'react';
import './App.css';

function App() {
  return (
    <div className="bg">
      <h1>edacity</h1>
      <p>coming soon</p>
    </div>
  );
}

export default App;
